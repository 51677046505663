import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Container from "../components/containers/container";
import Box from "../components/containers/box";
import IconMiniTitle from "../components/elements/iconMiniTitle";
import IconsListOrizontalIcon from "../components/icons-list/orizontal/iconsListOrizontalIcon";
import ContactContainer from "../components/elements/contactContainer";

const SoftwarePage = () => (
  <Layout>
    <Helmet>
      <title>Software Engineering</title>
      <meta
        name='description'
        content='I nostri tecnici programmatori sono in grado di sviluppare software per i più importanti marchi dei PLC in commercio, integrati da interfacce uomo-macchina su pannelli operatori o PC industriali.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='/image/social/og-software-elettronica-scarpa.png' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Software Engineering | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content='I nostri tecnici programmatori sono in grado di sviluppare software per i più importanti marchi dei PLC in commercio, integrati da interfacce uomo-macchina su pannelli operatori o PC industriali.'
      />
      <meta property='twitter:image' content='/image/social/og-software-elettronica-scarpa.png' />
    </Helmet>
    <Herobanner background='software'>
      <h1 className='mb-6'>Software Engineering</h1>
    </Herobanner>

    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <p className='mb-6'>
              I nostri tecnici programmatori sono in grado di sviluppare software per i più importanti marchi dei PLC in commercio, integrati da interfacce uomo-macchina su pannelli operatori o PC
              industriali. Disponiamo, in aggiunta, di un reparto dedicato allo studio e realizzazione di schede elettroniche basate su microcontrollori.<br></br>Abbiamo esperienza anche nella
              programmazione di robot antropomorfi e nei sistemi di motion control avanzato. Offriamo inoltre la possibilità di interventi all'estero, per assisterti durante le fasi di avviamento del
              progetto.
            </p>

            <div className='row'>
              <div className='col-12 col-lg-4'>
                <div className='row'>
                  <div className='col-6 col-lg-12'>
                    <img className='img-fluid mb-2' alt='homepage' src='/image/servizi/software-1.jpg' />
                  </div>
                  <div className='col-6 col-lg-12'>
                    <img className='img-fluid' alt='homepage' src='/image/servizi/software-2.jpg' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-8'>
                <img className='img-fluid' alt='homepage' src='/image/servizi/software-3.jpg' />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Container>

    <Container customClass='py-2' />
    <Container customClass='py-10'>
      <div className='col-lg-3 order-2 order-md-1'>
        <div className='c-brochure'>
          <ul>
            <li>
              <a href='/progettazione'>
                <IconsListOrizontalIcon size={"small"} title={"Progettazione"} icon={"progettazione"}></IconsListOrizontalIcon>
              </a>
            </li>
            <li>
              <a href='/software' className='active'>
                {" "}
                <IconsListOrizontalIcon size={"small"} title={"Software Engineering"} icon={"software"}></IconsListOrizontalIcon>
              </a>
            </li>
            <li>
              <a href='/quadri-elettrici'>
                {" "}
                <IconsListOrizontalIcon size={"small"} title={"Realizzazione quadri elettrici e impianti"} icon={"realizzazione"}></IconsListOrizontalIcon>
              </a>
            </li>
            <li>
              <a href='/retrofitting'>
                {" "}
                <IconsListOrizontalIcon size={"small"} title={"Retrofitting"} icon={"retrofitting"}></IconsListOrizontalIcon>
              </a>
            </li>
            <li>
              <a href='/teleassistenza'>
                {" "}
                <IconsListOrizontalIcon size={"small"} title={"Teleassistenza da remoto"} icon={"teleassistenza"}></IconsListOrizontalIcon>
              </a>
            </li>
          </ul>
          <div className='c-brochure__download'>
            <h6>Brochure aziendale</h6>
            <p>Scarica il documento e leggi un approfondimento su tutte le soluzioni e i servizi disponibili. </p>
            <a className='e-button-outline e-button--block' href='brochure-elettronica-scarpa.pdf' title='Elettronica Scarpa - Brochure'>
              Scarica la brochure
              <svg version='1.1' id='Livello_1' x='0px' y='0px' viewBox='0 0 24 24'>
                <path className='st0' d='M19,20.5v-2H5v2H19z' />
                <g className='arrow'>
                  <polygon className='st0' points='19,9.5 15,9.5 15,3.5 9,3.5 9,9.5 5,9.5 12,16.5 	' />
                  <polygon className='st0' points='11,11.5 11,5.5 13,5.5 13,11.5 14.2,11.5 12,13.7 9.8,11.5 	' />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className='col-lg-8 offset-lg-1 col-10 offset-1 order-1 order-md-2'>
        <IconMiniTitle>I nostri punti di forza</IconMiniTitle>
        <h3 className='mt-4 mb-9'>Tre motivi per collaborare con noi. </h3>
        <p>
          Perché vuoi qualità, ma anche affidabilità. Perché vuoi tecnologia all’avanguardia, ma anche un numero di telefono da chiamare se si verifica un imprevisto. Perché hai bisogno di interventi
          celeri, di personale preparato, di qualcuno che sia davvero in grado di supportare la tua operatività. Perché anche noi crediamo che “assistenza” non significhi solo “manda una mail”. Perché
          a volte la puntualità è tutto, e non tutti sono in grado di gestire grandi progetti con un metodo agile. Perché quello industriale è un mondo complesso, ma con a fianco noi di Elettronica
          Scarpa diventa più semplice.{" "}
        </p>
        <ul className='no-style mt-4'>
          <li>
            <img src='/image/icone/icon-check.svg' alt='' />
            Prodotti certificati
          </li>
          <li>
            <img src='/image/icone/icon-check.svg' alt='' />
            Supporto tecnico{" "}
          </li>
          <li>
            <img src='/image/icone/icon-check.svg' alt='' />
            Aggiornamento costante{" "}
          </li>
          <li>
            <img src='/image/icone/icon-check.svg' alt='' />
            Soluzioni su specifiche
          </li>
        </ul>
      </div>
    </Container>
    <ContactContainer notAligned={true} />
  </Layout>
);

export default SoftwarePage;
